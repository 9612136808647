import { JSX } from "react"
import Header from "./Header"
import Footer from "./Footer"
import { useEffect } from "react"

function Welcome(): JSX.Element {
    useEffect(() => {
        document.title = "Welcome to Gemt!"
    }, [])

    return (
        <div className="welcome h-full overflow-hidden">
            <Header />
            <main className="h-[calc(100%-56px)] overflow-y-auto">
                <div className="h-auto mx-auto max-w-7xl my-4 px-8 pb-8">
                    <div className="h-64 sm:w-8/12 mx-auto text-white">
                        <h1 className="h-auto max-w-48 mb-4 mx-auto text-center uppercase font-semibold text-2xl">Welcome to Gemt</h1>

                        <p>
                            Welcome to Gemt, the easiest way to save your bookmarks.
                        </p>

                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Welcome
